import React, { useContext } from "react";

import { QueryClient, QueryClientProvider } from 'react-query'

import MapContainer from "./components/MapContainer";
import Filters from "./components/Filters";

import "./styles/App.css";
import "./styles/owstyle.css";
import { MapProvider, mapContext } from "./context/mapContext";
import SightingsExplorer from "./components/explorer/ExplorerPanel";

const queryClient = new QueryClient()

const AppWithQueryClient = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
};

const ShowHideExplorer = () => {
  const { showHideExplorer, explorerFlexGrow } = useContext(mapContext);
  const shStyle = {
    transform: `translate(var(--show-hide-offset-x), var(--show-hide-offset-y)) rotate(calc(var(--show-hide-rotate) + ${explorerFlexGrow *
      180}deg))`,
  };
  return <button className="showHide" onClick={showHideExplorer} style={shStyle} />;
};

function App() {
  const queryParameters = new URLSearchParams(window.location.search);

  return (
    <MapProvider>
      <div className="App">
        <SightingsExplorer />
        <div className="map-area-container">
          <ShowHideExplorer />
          <MapContainer />
          <Filters categoryType={queryParameters.get("categoryType") || "placetype"} />
        </div>
      </div>
    </MapProvider>
  );
}

export default AppWithQueryClient;
