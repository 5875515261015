import React, { useEffect, useRef, useState } from "react";
import { getNiceTaxon } from "../SightingsExplorer";
import ThroughTheYear from "./ThroughTheYear";
import { Transition } from "react-transition-group";
import InfoAboutSpecies from "./InfoAboutSpecies";
import ladybird from "../../../img/ladybird.png";

const clamp = (value, minValue, maxValue) => Math.max(Math.min(value, maxValue), minValue);

export const fetchNbnSightings = async (params, setData) => {
  setData({});
  const { lat, lon, kmRadius, taxon, month } = params;
  const baseUrl = "https://records-ws.nbnatlas.org/explore/";
  if (taxon !== "ALL_SPECIES") {
    params.queryType = "species";
  }
  const urlSuffix = params.queryType === "species" ? `group/${taxon}` : "groups";
  let url = new URL(baseUrl + urlSuffix);
  let searchParams = {
    lat,
    lon,
    radius: kmRadius,
    fq: "(occurrence_decade_i:2010 OR occurrence_decade_i:2020)",
  };
  if (params.queryType === "species") {
    searchParams["start"] = 0;
    searchParams["pageSize"] = 500;
    searchParams["sort"] = "count";
  }

  if (month) {
    searchParams.fq += ` AND month:"${month}"`;
  }

  Object.entries(searchParams).forEach(([k, v]) => {
    url.searchParams.append([k], v);
  });

  await fetch(url)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Something went wrong");
    })
    .then((data) => {
      if (data.errorType) {
        setData({ error: data });
      } else {
        setData({ query: params, data, searchParams, taxon });
      }
    })
    .catch((err) => {
      console.log("error caught", err);
      setData({ error: err });
    });
};

const SpeciesInfoSection = ({ in: inProp, speciesData, showTty, setUKCount, isSpeciesLevel }) => {
  const transitionStyles = {
    entering: { height: showTty ? "20px" : "0px" },
    entered: { height: "300px" },
    exiting: { height: "300px" },
    exited: { height: showTty ? "20px" : "0px" },
  };
  const nodeRef = useRef(null);
  const detailsRef = useRef(null);
  const [detailsWidth, setDetailsWidth] = useState(20);
  const setWidthIfExists = (ref) => {
    if (ref.current) {
      setDetailsWidth(ref.current.offsetWidth);
    }
  };
  useEffect(() => {
    setWidthIfExists(detailsRef);
    window.addEventListener("resize", () => {
      setWidthIfExists(detailsRef);
    });
  }, [detailsRef]);
  if (!isSpeciesLevel) {
    return;
  }
  return (
    <tr className="additional-row-info">
      <Transition nodeRef={nodeRef} in={inProp} timeout={200}>
        {(state) => (
          <td style={{ ...transitionStyles[state] }} colSpan="2">
            <div className="additional-row-info-container" ref={detailsRef}>
              {<InfoAboutSpecies speciesData={speciesData} inProp={inProp} />}
              {(inProp || showTty) && (
                <ThroughTheYear
                  speciesBinomialName={speciesData.name}
                  parentWidth={detailsWidth}
                  setUKCount={setUKCount}
                />
              )}
            </div>
          </td>
        )}
      </Transition>
    </tr>
  );
};

const getRichness = (actualCount, ukCount, month, kmRadius) => {
  if (!ukCount) {
    return;
  }
  let ukkm2 = 242495;
  let thiskm2 = Math.PI * kmRadius ** 2;
  let expect = (ukCount / ukkm2) * thiskm2;
  let mdivide = month ? 6 : 1;

  return (actualCount / expect) * mdivide * 0.3;
};

const NbnSpeciesInfo = ({
  d,
  query,
  month,
  kmRadius,
  setData,
  setLoading,
  isSpeciesLevel,
  setTaxon,
  showTty,
}) => {
  const metric = isSpeciesLevel ? "count" : "speciesCount";
  const normMetric = isSpeciesLevel ? "normCount" : "normSpeciesCount";
  const [showRowInfo, setShowRowInfo] = useState(false);
  const [ukCount, setUKCount] = useState(0);
  const richness = getRichness(d[metric], ukCount, month, kmRadius);
  const clampedRichness = clamp(richness, 0, 100);

  return (
    <>
      <tr
        className={"species-count-row"}
        onClick={() => {
          if (isSpeciesLevel) {
            setShowRowInfo(!showRowInfo);
          } else {
            setLoading(true);
            setTaxon(d.name);
            fetchNbnSightings(
              { ...query, taxon: d.name, queryType: "species" },
              setData,
              setLoading
            );
          }
        }}
      >
        <td>
          <div className={"table-species-name"}>{getNiceTaxon(d.commonName || d.name)}</div>
        </td>
        <td className={"number-cell"}>
          {
            <div
              className="div-bar-horizontal"
              style={{
                width: `${d[normMetric] * 100}%`,
                background: richness
                  ? `hsl(${158 + 1.2 * clampedRichness}deg ${30 + 0.5 * clampedRichness}% ${75 -
                      0.5 * clampedRichness}%)`
                  : "rgb(200, 200, 200)",
              }}
            >
              {d[normMetric] > 0.05 ? d[metric] : ""}
            </div>
          }
        </td>
      </tr>
      <SpeciesInfoSection
        in={showRowInfo}
        speciesData={d}
        showTty={showTty}
        setUKCount={setUKCount}
        isSpeciesLevel={isSpeciesLevel}
      />
    </>
  );
};

const NbnSightings = ({ nbnData, setData, setTaxon, setLoading, kmRadius, month }) => {
  if (nbnData.error) {
    return <div>{nbnData.error.message}</div>;
  }
  useEffect(() => {
    setLoading(false);
  });
  const { query, data, searchParams, taxon } = nbnData;
  const [showTty, setShowTty] = useState(false);
  const isSpeciesLevel = query.queryType === "species";
  data.sort((a, b) => b.count - a.count);
  const filteredData = data.filter((d) => d.count > 0);
  const maxCount = filteredData[0].count;
  const maxSpeciesCount = filteredData[0].speciesCount;
  const enrichedData = data.map((d) => ({
    ...d,
    normCount: d.count / maxCount,
    normSpeciesCount: d.speciesCount / maxSpeciesCount,
  }));

  return (
    <div className="sightings-table-container">
      {isSpeciesLevel && (
        <section className="sightings-header-buttons">
          <button
            className={"generic-button"}
            onClick={() => {
              setShowTty(!showTty);
            }}
            style={{
              background: showTty
                ? "#fff"
                : "linear-gradient(-10deg, hsl(158, 40%, 75%) 49%, transparent 51%, transparent)",
            }}
          >
            {showTty ? "Hide" : "Show"} species trends (UK)
          </button>
            <a
              href={`https://species-quiz.netlify.app/?obsNear=true&taxon=${taxon}&${Object.entries(
                searchParams
              )
                .filter(([k, v]) => ["lat", "lon", "radius", "fq"].includes(k))
                .map(([k, v]) => k + "=" + v)
                .join("&")}`}
              target="_blank"
            >
              <img src={ladybird} className="button-mini-image" />
              <p>Quiz these species</p>
            </a>
        </section>
      )}
      <table className="sightings-table">
        {/* <thead>
          <tr>
            <th>{isSpeciesLevel ? getNiceTaxon(query.taxon) : "Taxon"}</th>
            <th>Number of Species</th>
          </tr>
        </thead> */}
        <tbody>
          {enrichedData.map((d) => (
            <NbnSpeciesInfo
              d={d}
              query={query}
              month={month}
              kmRadius={kmRadius}
              setData={setData}
              setLoading={setLoading}
              isSpeciesLevel={isSpeciesLevel}
              setTaxon={setTaxon}
              showTty={showTty}
              key={d.name}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NbnSightings;
