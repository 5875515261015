import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { addLayerToMap } from "../scripts/mapUtils";
import { mapContext } from "../context/mapContext";
import { JEFSAPI_BASE_URI } from "../scripts/constants";

const jefsapiUris = {
  topic: `${JEFSAPI_BASE_URI}/outwhere/features-for-topic/`,
  placetype: `${JEFSAPI_BASE_URI}/outwhere/features-for-placetype/`,
}

const fetchMapFeatures = async (uri, placetype) => {
  const res = await fetch(uri + placetype);
  return res.json();
}

function GetPlacesForPlacetype({ categoryType, setLoaded, category }) {
  const { mapRef, setPopupLngLat, setPopupContent, showExplorer } = useContext(mapContext);
  const jefsapiUri = jefsapiUris[categoryType];
  const map = mapRef.current;

  const { data, error, isLoading } = useQuery(`${category}-features`, () => fetchMapFeatures(jefsapiUri, category));

  useEffect(() => {
    if (!error && !isLoading && data) {
      setLoaded(true);
    }
  });

  if (isLoading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>encountered an error: {error.message}</div>;
  }
  addLayerToMap(map, data, category, categoryType, setPopupLngLat, setPopupContent, showExplorer);
  return <div>Loaded.</div>;
}

export default GetPlacesForPlacetype;
